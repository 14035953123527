<template>
  <v-dialog
    v-model="revenueModalDialog"
    persistent
    max-width="500px"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("Revenue Reports") }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row class="space">
            <v-col cols="12" sm="12" md="12">
              <v-tabs
                v-model="tab"
                background-color="primary"
                dark
                id="printTabsHeaders"
              >
                <v-tab>
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="imageDiv text-center">
                        <img src="../../assets/icons/summary.png" alt />
                      </div>
                    </v-col>
                    <v-col cols="12" md="12">
                      <div class="content main-color">{{ $t("Summary") }}</div>
                    </v-col>
                  </v-row>
                </v-tab>
                <v-tab class="text-center">
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="imageDiv text-center">
                        <img src="../../assets/icons/details.png" alt />
                      </div>
                    </v-col>
                    <v-col cols="12" md="12">
                      <div class="content main-color">
                        {{ $t("Details") }}
                      </div>
                    </v-col>
                  </v-row>
                </v-tab>
              </v-tabs>

              <v-card id="printTabsCard">
                <v-tabs-items v-model="tab" id="printTabsItems">
                  <!-- Summary -->
                  <v-tab-item>
                    <v-form ref="form" v-model="valid">
                      <v-row>
                        <v-col cols="12">
                          <div class="form-group">
                            <label>{{ $t("Date") }}</label>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" class="parent">
                          <div class="form-group">
                            <label>{{ $t("From") }}</label>
                            <v-text-field
                              v-model="partnerData.from"
                              solo
                              append-icon="calendar_today"
                              id="partnerDatafrom"
                              autocomplete="off"
                              :rules="[
                                startDateRule(partnerData.from, partnerData.to)
                              ]"
                            ></v-text-field>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="form-group">
                            <label>{{ $t("to") }}</label>
                            <v-text-field
                              v-model="partnerData.to"
                              solo
                              append-icon="calendar_today"
                              id="partnerDatato"
                              autocomplete="off"
                              :rules="[
                                startDateRule(partnerData.from, partnerData.to)
                              ]"
                            ></v-text-field>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <div class="form-group">
                            <label>{{ $t("Academic Year(s)") }}</label>
                            <v-autocomplete
                              v-model="partnerData.academicYears"
                              solo
                              :items="academicYears"
                              required
                              @change="hideLabel = true"
                              item-text="name"
                              item-value="id"
                              :rules="[validationRules.required]"
                              multiple
                              chips
                            ></v-autocomplete>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <div class="form-group">
                            <label>{{ $t("Grade(s)") }}</label>
                            <v-autocomplete
                              v-model="partnerData.grades"
                              solo
                              :items="grades"
                              required
                              @change="hideLabel = true"
                              item-text="name"
                              item-value="id"
                              :rules="[validationRules.required]"
                              multiple
                              chips
                            ></v-autocomplete>
                          </div>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-tab-item>
                  <!-- details -->
                  <v-tab-item>
                    <v-form ref="form" v-model="valid">
                      <v-row>
                        <v-col cols="12">
                          <div class="form-group">
                            <label>{{ $t("Date") }}</label>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" class="parent">
                          <div class="form-group">
                            <label>{{ $t("From") }}</label>
                            <v-text-field
                              v-model="partnerData.from"
                              solo
                              append-icon="calendar_today"
                              id="partnerDatafrom2"
                              autocomplete="off"
                              :rules="[
                                startDateRule(partnerData.from, partnerData.to)
                              ]"
                            ></v-text-field>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="form-group">
                            <label>{{ $t("to") }}</label>
                            <v-text-field
                              v-model="partnerData.to"
                              solo
                              append-icon="calendar_today"
                              id="partnerDatato2"
                              autocomplete="off"
                              :rules="[
                                startDateRule(partnerData.from, partnerData.to)
                              ]"
                            ></v-text-field>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <div class="form-group">
                            <label>{{ $t("Academic Year(s)") }}</label>
                            <v-autocomplete
                              v-model="partnerData.academicYears"
                              solo
                              :items="academicYears"
                              required
                              @change="hideLabel = true"
                              item-text="name"
                              item-value="id"
                              :rules="[validationRules.required]"
                              multiple
                              chips
                            ></v-autocomplete>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <div class="form-group">
                            <label>{{ $t("Grade(s)") }}</label>
                            <v-autocomplete
                              v-model="partnerData.grades"
                              solo
                              :items="grades"
                              required
                              @change="hideLabel = true"
                              item-text="name"
                              item-value="id"
                              :rules="[validationRules.required]"
                              multiple
                              chips
                            ></v-autocomplete>
                          </div>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel cancelBtn" @click="close">{{
          $t("Close")
        }}</v-btn>
        <v-btn
          class="modal-btn-save saveBtn"
          :loading="loading"
          @click="PrintPartnerReport"
          >{{ $t("Print") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
// import ACL from "../../acl";
// import VueTagsInput from "@johmun/vue-tags-input";
import moment from "moment";
export default {
  name: "revenueReportsComponent",
  props: ["revenueModalDialog"],
  data() {
    return {
      tab: null,
      academicYears: [],
      grades: [],
      partnerData: {
        academicYears: [],
        grades: [],
        from: "",
        to: ""
      },
      // detailesPere: ACL.checkPermission("revenue-details-reports"),
      // sumaryPere: ACL.checkPermission("revenue-summary-reports"),
      valid: true,
      loading: false
      // partners: [],
    };
  },
  mixins: [validationMixin],
  watch: {
    revenueModalDialog: {
      handler() {
        if (this.revenueModalDialog == true) {
          this.getGrades();
          this.getYears();
          setTimeout(() => {
            this.activateDatePicker();
          }, 1000);
        }
      }
    },
    tab: {
      handler() {
        this.activateDatePicker();
      }
    }
  },
  methods: {
    close() {
      this.revenueModalDialog = false;
      this.partnerData = {
        academicYears: [],
        grades: [],
        from: "",
        to: ""
      };
      this.$emit("childToParent", false);
    },
    activateDatePicker() {
      let _this = this;
      $(document).ready(function() {
        $("#partnerDatafrom").calendarsPicker({
          dateFormat: "dd/mm/yyyy",

          onSelect: function(date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.partnerData.from = dateH;
            } else {
              _this.partnerData.from = "";
            }
          }
        });
        $("#partnerDatato").calendarsPicker({
          dateFormat: "dd/mm/yyyy",

          onSelect: function(date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.partnerData.to = dateH;
            } else {
              _this.partnerData.to = "";
            }
          }
        });
        $("#partnerDatafrom2").calendarsPicker({
          dateFormat: "dd/mm/yyyy",

          onSelect: function(date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.partnerData.from = dateH;
            } else {
              _this.partnerData.from = "";
            }
          }
        });
        $("#partnerDatato2").calendarsPicker({
          dateFormat: "dd/mm/yyyy",

          onSelect: function(date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.partnerData.to = dateH;
            } else {
              _this.partnerData.to = "";
            }
          }
        });
      });
    },
    startDateRule(start, end) {
      var startDate = moment(start, "DD/MM/YYYY");
      var endDate = moment(end, "DD/MM/YYYY");
      return startDate > endDate
        ? "Start date should be less than end date"
        : true;
    },
    getYears() {
      axios
        .get(this.getApiUrl + "/reports/revenue/getYears", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          this.academicYears = response.data.data;
        });
    },
    getGrades() {
      axios
        .get(this.getApiUrl + "/reports/revenue/getGrades", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          this.grades = response.data.data;
        });
    },
    printSummary() {
      this.loading = true;
      axios
        .post(this.getApiUrl + "/reports/revenue/summary", this.partnerData, {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          // console.log(response.data.data);
          var link = response.data.data;
          window.open(link, "_blank");
        });
      this.loading = false;
    },
    printDetails() {
      this.loading = true;
      axios
        .post(this.getApiUrl + "/reports/revenue/details", this.partnerData, {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          var link = response.data.data;
          window.open(link, "_blank");
        });
      this.loading = false;
    },
    PrintPartnerReport() {
      if (this.valid == false) {
        this.validate();
      } else {
        if (this.tab == 0) {
          this.printSummary();
        } else {
          this.printDetails();
        }
      }
    }
  },
  mounted() {
    this.activateDatePicker();
    // this.getGrades();
    // this.getYears();
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.innerDiv label {
  margin-top: 0 !important;
}
.imageDiv {
  img {
    max-width: 40px;
  }
}
#partnerDatafrom {
  position: absolute;
}
.parent {
  position: relative;
  z-index: 99;
}
@media (max-width: 600px) {
  .imageDiv {
    img {
      max-width: 30px;
    }
  }
  .content {
    font-size: 10px;
  }
}
.content {
  font-weight: normal !important;
}
.radio-section {
  margin-top: 0;
}

label {
  font-size: 14px !important;
  margin-top: 0 !important;
}
</style>
