<template>
  <v-app>
    <v-main>
      <div id="content-loggedIn" v-if="isLogin() && !isStudentCheckout">
        <NavBar></NavBar>
        <SideBar></SideBar>
        <v-container
          id="wrapper"
          :class="
            !getSideBarMin &&
            (lang == 'undefined' || lang == undefined || lang == 'en')
              ? 'active'
              : lang == 'ar' && !getSideBarMin
              ? 'activeRtl'
              : ''
          "
        >
          <!-- :class="
            !getSideBarMin && (lang == undefined || lang == 'en')
              ? 'active'
              : lang == 'ar' && !getSideBarMin
              ? 'activeRtl'
              : ''
          " -->
          <router-view />
        </v-container>
      </div>
      <div
        class="checkout-background"
        id=""
        v-if="isLogin() && isStudentCheckout"
      >
        <NavBar></NavBar>
        <!-- <SideBar></SideBar> -->
        <v-container fluid class="pa-0">
          <router-view />
        </v-container>
      </div>
      <Footer v-if="isLogin()"></Footer>

      <div class="" id="content" v-if="!isLogin()">
        <v-container id="" fluid>
          <router-view />
        </v-container>
        <!-- <Footer></Footer> -->
      </div>
    </v-main>
  </v-app>
</template>

<script>
window.$ = require("jquery");
window.JQuery = require("jquery");
// require("expose?$!expose?jQuery!jquery");
import "./assets/js/plugins.js";
import "./assets/js/popper.min.js";
import "./assets/js/bootstrap.min.js";
import "./assets/js/jquery.counterup.min.js";
import "./assets/js/moment.min.js";
// import "./assets/js/jquery.waypoints.min.js";
import "./assets/js/jquery.scrollUp.min.js";
// import "./assets/js/jquery.dataTables.min.js";
//  import "./assets/js/fullcalendar.min.js";
//  import "./assets/js/Chart.min.js";
// import "./assets/css/vuetifyCustomStyle.css";
import "./assets/js/main.js";

import NavBar from "./components/NavBarComponent";
import SideBar from "./components/SideBarComponent";
import Footer from "./components/FooterComponent";

// for socket for ACL
// var socketUrl = "backend.shifters.tech";
// var socketUrl = "sms.test";
// import Echo from "laravel-echo";
// import axios from "axios";
window.Pusher = require("pusher-js");
// if (window.location.protocol == "") {
//   window.Echo = new Echo({
//     broadcaster: "pusher",
//     key: "SMSAppKey",
//     cluster: "mt1",
//     wsHost: socketUrl,
//     wsPort: 6001,
//     wssPort: 6001,
//     disableStats: true,
//     nabledTransports: ["ws", "wss"],
//     forceTLS: true,
//     encrypted: false,
//   });
// }
// end socket
export default {
  name: "App",

  components: {
    NavBar,
    SideBar,
    Footer,
  },

  data: () => ({
    user_id: localStorage.id,
    lang: localStorage.locale,
    isStudentCheckout: false,
  }),
  watch: {
    "$route.path": {
      handler() {
        this.checkPage();
      },
      deep: true,
    },
  },
  methods: {
    isLogin() {
      return localStorage.login && localStorage.login == "true" ? true : false;
    },
    checkPage() {
      if (window.location.pathname == "/checkout") {
        this.isStudentCheckout = true;
      } else {
        this.isStudentCheckout = false;
      }
    },
  },
  mounted() {
    this.checkPage();
    if (process.env.VUE_APP_SOCKET_URL != undefined) {
      this.$socket.channel("ShouldLogOut").listen("ShouldLogOut", (e) => {
        console.log(e);
        if (parseInt(e.user_id) == parseInt(localStorage.id)) {
          console.log("ShouldLogOut event called");
          localStorage.clear();
          window.location = "/login";
        }
      });
    }
    // console.log("connected");
    // localStorage.clear();
    // window.location = "/login";
    // window.Echo.channel("AclChanged").listen("AclChanged", e => {
    //   let users_id = e.users_id;
    //   if (users_id.indexOf(parseInt(this.user_id)) > -1) {
    //     // console.log(this.user_id);
    //     // call the api to retrieve the permissions and menues again, then save in local storage.
    //     // localStorage.clear();
    //     // window.location = "/login";
    //     axios
    //       .post(
    //         this.getApiUrl + "/selectRole",
    //         {
    //           role: localStorage.selectedRole
    //         },
    //         {
    //           headers: {
    //             Authorization: "Bearer " + localStorage.token
    //           }
    //         }
    //       )
    //       .then(response => {
    //         localStorage.permissionsObject = JSON.stringify(
    //           response.data.data.permissions
    //         );
    //         localStorage.menus = JSON.stringify(response.data.data.menus);
    //         window.location.reload();
    //       });
    //   }
    // });
    // window.Echo.channel("RolesChanged").listen("RolesChanged", e => {
    //   let user_id = e.user_id;
    //   if (user_id == this.user_id) {
    //     console.log(this.user_id);
    //     // call the api to retrieve the permissions and menues again, then save in local storage.
    //     // localStorage.clear();
    //     // window.location = "/login";
    //     axios
    //       .get(this.getApiUrl + "/users/getUserRoles/" + this.user_id, {
    //         headers: {
    //           Authorization: "Bearer " + localStorage.token
    //         }
    //       })
    //       .then(response => {
    //         localStorage.roles = JSON.stringify(response.data.data);
    //         window.location.reload();
    //       });
    //   }
    // });
  },
};
</script>

<style lang="scss">
@import "styles/_variables.scss";
@import "styles/_modals.scss";

.checkout-background {
  // padding-top: 5rem;
  min-width: 100%;
  // min-height: 100vh;
  background: url("assets/bg.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

@media (max-width: 920px) and (min-width: 600px) {
  .checkout-background {
    background: url("assets/bg-md.png");
    background-attachment: fixed;
    background-position: center;
    background-size: contain;
  }
}

@media (max-width: 600px) {
  .checkout-background {
    background: url("assets/bg-sm.png");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
  }
}

// should apply on all views
#content-loggedIn {
  padding-bottom: 2rem;
}
#wrapper {
  transition: all 0.4s ease 0s;
  position: relative;
}
#wrapper.activeRtl {
  text-align: right;
}
@media print {
  #wrapper.active {
    padding-left: 0;
  }
  #wrapper.activeRtl {
    padding-right: 0;
  }
}

/* not logged in style */
#content .container {
  padding: 0;
}

/* end not logged in style */

/* scrollUp */
#scrollUp {
  background-color: $main-color !important;
  border: 2px solid #5a99e6 !important;
}

@media (min-width: 960px) {
  #wrapper {
    left: 25px;
  }
  #wrapper.active {
    // padding-left: 200px;
    left: 125px !important;
  }
  #wrapper.activeRtl {
    // padding-left: 200px;
    right: 125px !important;
  }
}
</style>
