import Vue from "vue";
import Vuex from "vuex";
import VueClipboard from "vue-clipboard2";
import axios from "axios";
Vue.use(VueClipboard);

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiUrl: "https://hr-backend.shifters.tech/api",
backendUrl: "https://hr-backend.shifters.tech",
    // apiUrl: "http://backend.shifters.tech/api",

    // apiUrl: "https:sms-new.test/api",

    // apiUrl: "https://sms.test/api",
    // mahmoud kamal local link
    //    apiUrl: "http://localhost/sms/api",

    // apiUrl: "http://localhost/sms/api",
    socketUrl: "hr-backend.shifters.tech",
    sidebarMin: false,
    sidebarDisplay: false,
    vacationsDays: []
  },
  getters: {
    getApiUrl(state) {
      return state.apiUrl;
    },
getBackendUrl(state) {
      return state.backendUrl;
    },
    getSideBarMin(state) {
      return state.sidebarMin;
    },
    getSidebarDisplay(state) {
      return state.sidebarDisplay;
    },
    getVacationsDays(state) {
      return state.vacationsDays;
    },
    getSocketUrl(state){
      return state.socketUrl;
    },
  },
  mutations: {
    setSideBarMin(state) {
      state.sidebarMin = !state.sidebarMin;
    },
    setSidebarDisplay(state) {
      state.sidebarDisplay = !state.sidebarDisplay;
    },
    setVacationDays(state) {
      // console.log("here");
      let today = new Date();
      let date =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear();

      axios
        .post(
          state.apiUrl + "/school/checkVacationDate",
          {
            date: date
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          if (response.data.status.error == false) {
            state.vacationsDays = response.data.data;
          }
        });
    }
  },
  actions: {},
  modules: {}
});

