<template>
  <!-- Sidebar Area Start Here -->

  <v-navigation-drawer
    v-model="drawer"
    class="main-sidebar not-printable"
    :class="currentAppLocale == 'ar' ? 'text-right' : ''"
    :expand-on-hover="expandOnHover"
    :mini-variant="miniVariant"
    :right="currentAppLocale == 'ar'"
    :src="bg"
    absolute
    dark
    stateless
    value="true"
  >
    <v-list dense nav class="py-0">
      <v-list-item two-line :class="miniVariant && 'px-0'">
        <v-list-item-avatar v-if="logo != 'null'">
          <img :src="logo" />
        </v-list-item-avatar>
        <v-list-item-avatar v-if="logo == 'null'">
          <img src="../assets/school_logo.png" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ school_name ? school_name : "SMS" }}
          </v-list-item-title>
          <v-list-item-title>
            <span>
              {{ gradeClass }}
            </span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list-item v-if="largeScreen">
        <v-btn icon @click.stop="setSideBarMin" v-if="miniVariant">
          <v-icon v-if="currentAppLocale != 'ar'">mdi-chevron-right</v-icon>
          <v-icon v-else>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon @click.stop="setSideBarMin" v-if="!miniVariant">
          <v-icon v-if="currentAppLocale != 'ar'">mdi-chevron-left</v-icon>
          <v-icon v-else>mdi-chevron-right</v-icon>
        </v-btn>
      </v-list-item>

      <v-list-item v-if="!largeScreen">
        <v-btn icon @click="closeSidebar">
          <v-icon>{{ $t("close") }}</v-icon>
        </v-btn>
      </v-list-item>

      <v-list-item link to="/" class="single">
        <v-list-item-icon :class="currentAppLocale == 'ar' ? 'mr-3' : ''">
          <i class="fas fa-chart-line"></i>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t("Dashboard") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- masterDatashowMenu -->
      <v-list-group
        no-action="no-action"
        v-if="canSee(schoolSetup)"
        ref="schoolSetup"
      >
        <v-list-item slot="activator">
          <div @click="masterDatashowMenu">
            <v-list-item-icon>
              <i class="fas fa-school"></i>
            </v-list-item-icon>
          </div>

          <v-list-item-title>{{ $t("School Setup") }}</v-list-item-title>

          <v-menu
            v-if="miniVariant"
            v-model="masterDataMenu.value"
            :close-on-click="masterDataMenu.closeOnClick"
            :close-on-content-click="masterDataMenu.closeOnContentClick"
            :offset-x="masterDataMenu.offsetX"
            :offset-y="masterDataMenu.offsetY"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <i class="fas fa-school"></i>
              </v-list-item-icon>
            </template>
            <v-list>
              <div v-for="(item, index) in schoolSetup" :key="index">
                <v-list-item v-if="item.enabled" :to="item.path">
                  <v-list-item-title v-if="item.enabled">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-list-item>

        <!-- ===============================loop to get all elements===================================== -->
        <div v-for="(item, index) in schoolSetup" :key="index">
          <v-list-item link :to="item.path" v-if="item.enabled">
            <v-list-item>
              <!-- <v-list-item-icon>
                  <i class="fas fa-chart-line"></i>
              </v-list-item-icon>-->
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </div>
      </v-list-group>
      <!-- Academic Setup -->
      <v-list-group
        no-action="no-action"
        v-if="canSee(AcademicSetup)"
        ref="academicSetup"
      >
        <v-list-item slot="activator">
          <div @click="academicSetupShowMenu">
            <v-list-item-icon>
              <v-icon style="margin-left: -3px; margin-top: -5px"
                >local_library</v-icon
              >
            </v-list-item-icon>
          </div>
          <v-list-item-title>{{ $t("Academic Setup") }}</v-list-item-title>

          <v-menu
            v-if="miniVariant"
            v-model="academicSetupMenu.value"
            :close-on-click="academicSetupMenu.closeOnClick"
            :close-on-content-click="academicSetupMenu.closeOnContentClick"
            :offset-x="academicSetupMenu.offsetX"
            :offset-y="academicSetupMenu.offsetY"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <i class="fas fa-school"></i>
              </v-list-item-icon>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in AcademicSetup"
                :key="index"
                :to="item.path"
              >
                <v-list-item-title v-if="item.enabled">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item>
        <div v-for="(item, index) in AcademicSetup" :key="index">
          <v-list-item link :to="item.path" v-if="item.enabled">
            <v-list-item>
              <!-- <v-list-item-icon>
                  <i class="fas fa-chart-line"></i>
              </v-list-item-icon>-->
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </div>
      </v-list-group>
      <!-- Student -->
      <v-list-group
        no-action="no-action"
        v-if="canSee(students)"
        ref="students"
      >
        <v-list-item slot="activator">
          <div @click="studentshowMenu">
            <v-list-item-icon>
              <i class="fas fa-user-graduate"></i>
            </v-list-item-icon>
          </div>

          <v-list-item-title>{{ $t("Students") }}</v-list-item-title>

          <v-menu
            v-if="miniVariant"
            v-model="studentsMenu.value"
            :close-on-click="studentsMenu.closeOnClick"
            :close-on-content-click="studentsMenu.closeOnContentClick"
            :offset-x="studentsMenu.offsetX"
            :offset-y="studentsMenu.offsetY"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <i class="fas fa-school"></i>
              </v-list-item-icon>
            </template>
            <v-list>
              <div v-for="(item, index) in students" :key="index">
                <v-list-item v-if="item.enabled" :to="item.path">
                  <v-list-item-title v-if="item.enabled">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-list-item>
        <div v-for="(item, index) in students" :key="index">
          <v-list-item link :to="item.path" v-if="item.enabled">
            <v-list-item>
              <!-- <v-list-item-icon>
                  <i class="fas fa-user-graduate"></i>
              </v-list-item-icon>-->
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </div>
      </v-list-group>
      <!-- Teachers -->
      <v-list-group
        no-action="no-action"
        v-if="canSee(teachers)"
        ref="teachers"
      >
        <v-list-item slot="activator">
          <div @click="teachershowMenu">
            <v-list-item-icon>
              <i class="fas fa-chalkboard-teacher"></i>
            </v-list-item-icon>
          </div>

          <v-list-item-title>{{ $t("Teachers") }}</v-list-item-title>

          <v-menu
            v-if="miniVariant"
            v-model="teachersMenu.value"
            :close-on-click="teachersMenu.closeOnClick"
            :close-on-content-click="teachersMenu.closeOnContentClick"
            :offset-x="teachersMenu.offsetX"
            :offset-y="teachersMenu.offsetY"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <i class="fas fa-school"></i>
              </v-list-item-icon>
            </template>
            <v-list>
              <div v-for="(item, index) in teachers" :key="index">
                <v-list-item v-if="item.enabled" :to="item.path">
                  <v-list-item-title v-if="item.enabled">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-list-item>
        <div v-for="(item, index) in teachers" :key="index">
          <v-list-item link :to="item.path" v-if="item.enabled">
            <v-list-item>
              <!-- <v-list-item-icon>
                  <i class="fas fa-user-graduate"></i>
              </v-list-item-icon>-->
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </div>
      </v-list-group>
      <!-- Finance -->
      <v-list-group
        no-action="no-action"
        v-if="canSee(fianacial)"
        ref="fianacial"
      >
        <v-list-item slot="activator">
          <div @click="financeShowMenu">
            <v-list-item-icon>
              <i class="fas fa-credit-card"></i>
            </v-list-item-icon>
          </div>
          <v-list-item-title>{{ $t("Finance") }}</v-list-item-title>

          <v-menu
            v-if="miniVariant"
            v-model="financeMenu.value"
            :close-on-click="financeMenu.closeOnClick"
            :close-on-content-click="financeMenu.closeOnContentClick"
            :offset-x="financeMenu.offsetX"
            :offset-y="financeMenu.offsetY"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <i class="fas fa-school"></i>
              </v-list-item-icon>
            </template>
            <v-list>
              <div v-for="(item, index) in fianacial" :key="index">
                <v-list-item v-if="item.enabled" :to="item.path">
                  <v-list-item-title v-if="item.enabled">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-list-item>
        <div v-for="(item, index) in fianacial" :key="index">
          <v-list-item link :to="item.path" v-if="item.enabled">
            <v-list-item>
              <!-- <v-list-item-icon>
                  <i class="fas fa-chart-line"></i>
              </v-list-item-icon>-->
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </div>
      </v-list-group>
      <!-- Library 
      <v-list-group no-action="no-action" v-if="canSee(digitalLibrary)">
        <v-list-item slot="activator">
          <div @click="digitalLibraryShowMenu">
            <v-list-item-icon>
              <i class="fas fa-book"></i>
            </v-list-item-icon>
          </div>
          <v-list-item-title>{{ $t("Digital Library") }}</v-list-item-title>

          <v-menu
            v-if="miniVariant"
            v-model="digitalLibraryMenu.value"
            :close-on-click="digitalLibraryMenu.closeOnClick"
            :close-on-content-click="digitalLibraryMenu.closeOnContentClick"
            :offset-x="digitalLibraryMenu.offsetX"
            :offset-y="digitalLibraryMenu.offsetY"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <i class="fas fa-school"></i>
              </v-list-item-icon>
            </template>
            <v-list>
              <div v-for="(item, index) in digitalLibrary" :key="index">
                <v-list-item v-if="item.enabled" :to="item.path">
                  <v-list-item-title v-if="item.enabled">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-list-item>
        <div v-for="(item, index) in digitalLibrary" :key="index">
          <v-list-item link :to="item.path" v-if="item.enabled">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </div>
      </v-list-group> -->
      <!-- Class Room -->
      <v-list-group
        no-action="no-action"
        v-if="canSee(classRoom)"
        ref="classRoom"
      >
        <v-list-item slot="activator">
          <div @click="ClassRoomShowMenu">
            <v-list-item-icon>
              <i class="fas fa-graduation-cap"></i>
              <!-- <v-icon medium>class</v-icon> -->
            </v-list-item-icon>
          </div>
          <v-list-item-title>{{ $t("Class Room") }}</v-list-item-title>

          <v-menu
            v-if="miniVariant"
            v-model="ClassRoomMenu.value"
            :close-on-click="ClassRoomMenu.closeOnClick"
            :close-on-content-click="ClassRoomMenu.closeOnContentClick"
            :offset-x="ClassRoomMenu.offsetX"
            :offset-y="ClassRoomMenu.offsetY"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <i class="fas fa-school"></i>
              </v-list-item-icon>
            </template>
            <v-list>
              <div v-for="(item, index) in classRoom" :key="index">
                <v-list-item v-if="item.enabled" :to="item.path">
                  <v-list-item-title v-if="item.enabled">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-list-item>
        <div v-for="(item, index) in classRoom" :key="index">
          <v-list-item link :to="item.path" v-if="item.enabled">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </div>
      </v-list-group>
      <!-- Item Setup -->
      <v-list-group
        no-action="no-action"
        v-if="canSee(itemsSetup)"
        ref="itemsSetup"
      >
        <v-list-item slot="activator">
          <div @click="itemMenuShowMenu">
            <v-list-item-icon>
              <i class="fas fa-swatchbook"></i>
            </v-list-item-icon>
          </div>
          <v-list-item-title>{{ $t("Items Setup") }}</v-list-item-title>

          <v-menu
            v-if="miniVariant"
            v-model="itemMenu.value"
            :close-on-click="itemMenu.closeOnClick"
            :close-on-content-click="itemMenu.closeOnContentClick"
            :offset-x="itemMenu.offsetX"
            :offset-y="itemMenu.offsetY"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <i class="fas fa-school"></i>
              </v-list-item-icon>
            </template>
            <v-list>
              <div v-for="(item, index) in itemsSetup" :key="index">
                <v-list-item v-if="item.enabled" :to="item.path">
                  <v-list-item-title v-if="item.enabled">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-list-item>
        <div v-for="(item, index) in itemsSetup" :key="index">
          <v-list-item link :to="item.path" v-if="item.enabled">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </div>
      </v-list-group>

      <!-- Warehouses -->
      <v-list-group
        no-action="no-action"
        v-if="canSee(Warehouses)"
        ref="Warehouses"
      >
        <v-list-item slot="activator">
          <div @click="Warehouses">
            <v-list-item-icon>
              <i class="fas fa-store"></i>
            </v-list-item-icon>
          </div>
          <v-list-item-title>{{ $t("Warehouses") }}</v-list-item-title>

          <v-menu
            v-if="miniVariant"
            v-model="Warehouses.value"
            :close-on-click="Warehouses.closeOnClick"
            :close-on-content-click="Warehouses.closeOnContentClick"
            :offset-x="Warehouses.offsetX"
            :offset-y="Warehouses.offsetY"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <i class="fas fa-school"></i>
              </v-list-item-icon>
            </template>
            <v-list>
              <div v-for="(item, index) in classRoom" :key="index">
                <v-list-item v-if="item.enabled" :to="item.path">
                  <v-list-item-title v-if="item.enabled">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-list-item>
        <div v-for="(item, index) in Warehouses" :key="index">
          <v-list-item link :to="item.path" v-if="item.enabled">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </div>
      </v-list-group>

      <!-- Reports -->
      <v-list-group v-if="canSee(Reports)" no-action="no-action" ref="Reports">
        <v-list-item slot="activator">
          <div @click="reportsShowMenu">
            <v-list-item-icon>
              <i class="fas fa-chart-bar"></i>
            </v-list-item-icon>
          </div>
          <v-list-item-title>{{ $t("Reports") }}</v-list-item-title>

          <v-menu
            v-if="miniVariant"
            v-model="reportsMenu.value"
            :close-on-click="reportsMenu.closeOnClick"
            :close-on-content-click="reportsMenu.closeOnContentClick"
            :offset-x="reportsMenu.offsetX"
            :offset-y="reportsMenu.offsetY"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <i class="fas fa-school"></i>
              </v-list-item-icon>
            </template>
            <v-list>
              <div v-for="(item, index) in Reports" :key="index">
                <v-list-item
                  v-if="item.enabled"
                  @click="OpenReportsModals(item.Model)"
                >
                  <v-list-item-title v-if="item.enabled">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-list-item>
        <div v-for="(item, index) in Reports" :key="index">
          <v-list-item
            link
            v-if="item.enabled"
            @click="OpenReportsModals(item.Model)"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </div>
      </v-list-group>
      <!-- end Reports -->

      <!-- Notifications -->
      <v-list-group
        v-if="canSee(Notifications)"
        no-action="no-action"
        ref="Notifications"
      >
        <v-list-item slot="activator">
          <div @click="notificationsShowMenu">
            <v-list-item-icon>
              <i class="fas fa-bell"></i>
            </v-list-item-icon>
          </div>
          <v-list-item-title>{{ $t("Notifications") }}</v-list-item-title>

          <v-menu
            v-if="miniVariant"
            v-model="NotificationsMenu.value"
            :close-on-click="NotificationsMenu.closeOnClick"
            :close-on-content-click="NotificationsMenu.closeOnContentClick"
            :offset-x="NotificationsMenu.offsetX"
            :offset-y="NotificationsMenu.offsetY"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <i class="fas fa-school"></i>
              </v-list-item-icon>
            </template>
            <v-list>
              <div v-for="(item, index) in Notifications" :key="index">
                <v-list-item v-if="item.enabled" :to="item.path">
                  <v-list-item-title v-if="item.enabled">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-list-item>
        <div v-for="(item, index) in Notifications" :key="index">
          <v-list-item link :to="item.path" v-if="item.enabled">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </div>
      </v-list-group>
      <!-- end notifications -->
      <!-- HR Moduels  -->
      <v-list-group
        v-if="checkpermitionsHr"
        no-action="no-action"
        ref="hrmodule"
      >
        <v-list-item slot="activator">
          <div @click="HRShowMenu">
            <v-list-item-icon>
              <v-icon>mdi-flash-red-eye</v-icon>
            </v-list-item-icon>
          </div>
          <v-list-item-title>{{ $t("Human Resources") }}</v-list-item-title>

          <v-menu
            v-if="miniVariant"
            v-model="HRShowMenu.value"
            :close-on-click="HRShowMenu.closeOnClick"
            :close-on-content-click="HRShowMenu.closeOnContentClick"
            :offset-x="HRShowMenu.offsetX"
            :offset-y="HRShowMenu.offsetY"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <i class="fas fa-school"></i>
              </v-list-item-icon>
            </template>
            <v-list>
              <div v-for="(item, index) in Hr" :key="index">
                <v-list-item v-if="item.enabled" :to="item.path">
                  <v-list-item-title v-if="item.enabled">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-list-item>
        <div v-for="(item, index) in Hr" :key="index">
          <v-list-item link :to="item.path" v-if="item.enabled">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </div>
      </v-list-group>
      <!-- end HR Moduels -->

      <!-- Educational History  -->
      <v-list-group no-action="no-action" v-if="checkpermitionsEducationalhistory">
        <v-list-item slot="activator">
          <div @click="EducationalHistoryShowMenu">
            <v-list-item-icon>
              <v-icon>history</v-icon>
            </v-list-item-icon>
          </div>
          <v-list-item-title>{{ $t("Educational History") }}</v-list-item-title>
          <v-menu
            v-if="miniVariant"
            v-model="educationalMenu.value"
            :close-on-click="educationalMenu.closeOnClick"
            :close-on-content-click="educationalMenu.closeOnContentClick"
            :offset-x="educationalMenu.offsetX"
            :offset-y="educationalMenu.offsetY"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <i class="fas fa-school"></i>
              </v-list-item-icon>
            </template>
            <v-list>
              <div v-for="(item, index) in EducationalHistory" :key="index">
                <v-list-item v-if="item.enabled" :to="item.path">
                  <v-list-item-title v-if="item.enabled">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-list-item>
        <div v-for="(item, index) in EducationalHistory" :key="index">
          <v-list-item link :to="item.path" v-if="item.enabled">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </div>
      </v-list-group>
      <!-- end Educational  History -->

      <!-- Users  -->
      <v-list-group no-action="no-action" v-if="canSee(Users)" ref="Users">
        <v-list-item slot="activator">
          <div @click="usersShowMenu">
            <v-list-item-icon>
              <i class="fas fa-users"></i>
            </v-list-item-icon>
          </div>
          <v-list-item-title>{{ $t("Users") }}</v-list-item-title>
          <v-menu
            v-if="miniVariant"
            v-model="usersMenu.value"
            :close-on-click="usersMenu.closeOnClick"
            :close-on-content-click="usersMenu.closeOnContentClick"
            :offset-x="usersMenu.offsetX"
            :offset-y="usersMenu.offsetY"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <i class="fas fa-school"></i>
              </v-list-item-icon>
            </template>
            <v-list>
              <div v-for="(item, index) in Users" :key="index">
                <v-list-item v-if="item.enabled" :to="item.path">
                  <v-list-item-title v-if="item.enabled">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-list-item>
        <div v-for="(item, index) in Users" :key="index">
          <v-list-item link :to="item.path" v-if="item.enabled">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </div>
      </v-list-group>
      <!-- end users -->
      <!-- Nedaa  -->
      <v-list-group
        no-action="no-action"
        v-if="hasNedaaPermission || hasRoleParent"
      >
        <v-list-item slot="activator">
          <div @click="usersShowMenu">
            <v-list-item-icon>
              <v-icon>mdi-microphone</v-icon>
            </v-list-item-icon>
          </div>
          <v-list-item-title>{{ $t("Nedaa") }}</v-list-item-title>
          <v-menu
            v-if="miniVariant"
            v-model="usersMenu.value"
            :close-on-click="usersMenu.closeOnClick"
            :close-on-content-click="usersMenu.closeOnContentClick"
            :offset-x="usersMenu.offsetX"
            :offset-y="usersMenu.offsetY"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-microphone</v-icon>
              </v-list-item-icon>
            </template>
            <v-list>
              <div v-for="(item, index) in Nedaa" :key="index">
                <v-list-item v-if="item.enabled" :to="item.path">
                  <v-list-item-title v-if="item.enabled">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-list-item>
        <div v-for="(item, index) in Nedaa" :key="index">
          <v-list-item link :to="item.path" v-if="item.enabled">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </div>
      </v-list-group>
      <!-- end Nedaa -->
    </v-list>
    <partnerReportComponent
      :PartnerModalDialog="PartnerModalDialog"
      v-on:childToParent="onChildClick"
    ></partnerReportComponent>

    <revenueReports
      :revenueModalDialog="revenueModalDialog"
      v-on:childToParent="onChildClick"
    ></revenueReports>
    <cashReportsComponent
      :cashModalDialog="cashModalDialog"
      v-on:childToParent="onChildClick"
    ></cashReportsComponent>
    <taxReportComponent
      :taxhModalDialog="taxhModalDialog"
      v-on:childToParent="onChildClick"
    ></taxReportComponent>
  </v-navigation-drawer>
</template>

<script>
import ACL from "../acl";
import partnerReportComponent from "./modals/partnerReportComponent";
import revenueReports from "./modals/revenueReportsComponent";
import cashReportsComponent from "./modals/cashReportsComponent";
// import cashReportsComponent from "./modals/cashReportsComponent";
import taxReportComponent from "./modals/taxReportComponent";
export default {
  name: "SideBar",
  props: {},
  data() {
    return {
      hasNedaaPermission:
        ACL.checkPermission("nedaa") && ACL.Role("Super-Admin") ? true : false,
      hasRoleParent: ACL.Role("Parent") || ACL.Role("Driver"),
      gradeClass:
        localStorage.gradeClass != undefined ? localStorage.gradeClass : "",
      checkpermitionsNotifications: ACL.checkMenu("Notifications"),
      checkpermitionsReports: ACL.checkMenu("Reports"),
      checkpermitionsHr: ACL.checkMenu("HR Configrations"),
      checkpermitionsEducationalhistory: ACL.Role("Parent") || ACL.Role("Student") || ACL.Role("Teacher") || ACL.Role("Super-Admin") || ACL.Role("Coordinator") || ACL.Role("Admin"),
      checkpermitionsStydent: ACL.checkMenu("Students"),
      PartnerModalDialog: false,
      revenueModalDialog: false,
      cashModalDialog: false,
      taxhModalDialog: false,
      superAdmin: ACL.Role("Super-Admin"),
      Coordinator: ACL.Role("Coordinator"),
      parent: ACL.Role("Parent"),
      student: ACL.Role("Student"),
      teacher: ACL.Role("Teacher"),
      AnyRoleElse: ACL.Role(["Accountant"]),
      /* ===========sidebar data =================== */

      items: [
        {
          title: this.$i18n.t("Dashboard"),
          icon: "mdi-view-dashboard",
          path: "/",
        },
        {
          title: this.$i18n.t("WarehouseTransaction"),
          icon: "mdi-view-dashboard",
          path: "/WarehouseTransaction",
          enabled: ACL.checkPermission("list-transactions"),
        },
        { title: this.$i18n.t("School Setup"), icon: "mdi-image" },
        { title: this.$i18n.t("Items Setup"), icon: "mdi-image" },
        { title: this.$i18n.t("Students"), icon: "mdi-help-box" },
        { title: this.$i18n.t("Fianancial"), icon: "mdi-view-dashboard" },
        { title: this.$i18n.t("Users"), icon: "mdi-image" },
      ],
      Warehouses: [
        {
          title: this.$i18n.t("Warehouse Transaction"),
          icon: "mdi-view-dashboard",
          path: "/WarehouseTransaction",
          enabled: ACL.checkPermission("list-transactions"),
        },
        {
          title: this.$i18n.t("Items List"),
          icon: "mdi-view-dashboard",
          path: "/ItemsList",
          enabled: ACL.checkPermission("list-transactions"),
        },
        {
          title: this.$i18n.t("Warehouse Items"),
          icon: "mdi-view-dashboard",
          path: "/WarehouseItems",
          enabled: ACL.checkPermission("view-warehouse-items"),
        },
      ],
      schoolSetup: [
        {
          title: this.$i18n.t("Master Data"),
          icon: "mdi-view-dashboard",
          path: "/MasterData",
          enabled: ACL.checkMenu("Master Data"),
        },
        {
          title: this.$i18n.t("Academic Periods"),
          icon: "mdi-view-dashboard",
          path: "/academics?page=1",
          enabled: ACL.checkPermission("list-academic-periods"),
        },
        {
          title: this.$i18n.t("Subject Types"),
          icon: "mdi-view-dashboard",
          path: "/subject_type?page=1",
          enabled: ACL.checkPermission("list-subject-types"),
        },
        {
          title: this.$i18n.t("Subjects"),
          icon: "mdi-view-dashboard",
          path: "/subjects?page=1",
          enabled: ACL.checkPermission("list-subjects"),
        },
        {
          title: this.$i18n.t("Grades"),
          icon: "mdi-view-dashboard",
          path: "/grades?page=1",
          enabled: ACL.checkPermission("list-grades"),
        },
        {
          title: this.$i18n.t("Configuration"),
          icon: "mdi-view-dashboard",
          path: "/workingdays?tab=0",
          enabled: ACL.checkMenu("Configrations"),
        },
        {
          title: this.$i18n.t("Grading System"),
          icon: "mdi-view-dashboard",
          path: "/gradingsystem",
          enabled: ACL.checkPermission("view-grading-system"),
        },
      ],
      AcademicSetup: [
        {
          title: this.$i18n.t("Curriculum Setup"),
          icon: "mdi-view-dashboard",
          path: "/curriculum_setup/active",
          enabled: ACL.checkMenu("Curriculum Setup"),
        },
        {
          title: this.$i18n.t("Subject Qualifiers"),
          icon: "mdi-view-dashboard",
          path: "/qualifier?page=1",
          enabled: ACL.checkPermission("list-qualifier"),
        },
        {
          title: this.$i18n.t("Class Management"),
          icon: "mdi-view-dashboard",
          path: "/classmanagement/all",
          enabled: ACL.checkPermission("list-class-managment"),
        },
      ],
      students: [
        {
          title: this.$i18n.t("All Applicants"),
          icon: "mdi-view-dashboard",
          path: "/applicants?page=1",
          enabled: ACL.checkPermission("list-applicants"),
        },
        {
          title: this.$i18n.t("Admission Exam"),
          icon: "mdi-view-dashboard",
          path: "/admissionExam?page=1",
          enabled: ACL.checkPermission("list-admission-exam"),
        },
        {
          title: this.$i18n.t("Students"),
          icon: "mdi-view-dashboard",
          path: "/students?page=1",
          enabled: ACL.checkPermission("list-students"),
        },
      ],
      teachers: [
        {
          title: this.$i18n.t("All Teachers"),
          icon: "mdi-view-dashboard",
          path: "/teachers?page=1",
          enabled: ACL.checkPermission("list-teachers"),
        },
        // {
        //  title: "My Schedule",
        //   icon: "mdi-view-dashboard",
        //  path: "/myschedule",
        //  enabled: ACL.Role("Teacher"),
        // },
      ],
      itemsSetup: [
        {
          title: this.$i18n.t("Items Categories"),
          icon: "mdi-view-dashboard",
          path: "/itemsCategories?page=1",
          enabled: ACL.checkPermission("list-items-categories"),
        },
        {
          title: this.$i18n.t("Items"),
          icon: "mdi-view-dashboard",
          path: "/items?page=1",
          enabled: ACL.checkPermission("list-items"),
        },
      ],
      Users: [
        {
          title: this.$i18n.t("Roles Management"),
          icon: "mdi-view-dashboard",
          path: "/role_management?page=1",
          enabled: ACL.checkMenu("User Managment"),
        },
        {
          title: this.$i18n.t("Users Management"),
          icon: "mdi-view-dashboard",
          path: "/user_management?page=1",
          enabled: ACL.checkMenu("User Managment"),
        },
        {
          title: this.$i18n.t("All Users"),
          icon: "mdi-view-dashboard",
          path: "/all_users?page=1",
          enabled: ACL.checkPermission("list-users"),
        },
        {
          title: this.$i18n.t("Manage Coordinators"),
          icon: "mdi-view-dashboard",
          path: "/manage-Coordinators?page=1",
          enabled: ACL.checkPermission("manage-coordinators"),
        },
        {
          title: this.$i18n.t("View As Teacher"),
          icon: "mdi-view-dashboard",
          path: "/login-as-teacher",
          enabled: ACL.checkPermission("login-as-teacher"),
        },
      ],
      fianacial: [
        {
          title: this.$i18n.t("Financial Status"),
          icon: "mdi-view-dashboard",
          path: "/financialstatus?page=1",
          enabled: ACL.checkPermission("list-financial-status"),
        },
        {
          title: this.$i18n.t("Fee Plans"),
          icon: "mdi-view-dashboard",
          path: "/feeplans?page=1",
          enabled: ACL.checkPermission("list-fee-plans"),
        },
        {
          title: this.$i18n.t("Taxes"),
          icon: "mdi-view-dashboard",
          path: "/taxes?page=1",
          enabled: ACL.checkPermission("list-taxes"),
        },
        {
          title: this.$i18n.t("Cash Points"),
          icon: "mdi-view-dashboard",
          path: "/cashpoints?page=1",
          enabled: ACL.checkPermission("list-cash-points"),
        },
        {
          title: this.$i18n.t("Partners"),
          icon: "mdi-view-dashboard",
          path: "/partners?page=1",
          enabled: ACL.checkPermission("list-partners"),
        },
        {
          title: this.$i18n.t("Invoices"),
          icon: "mdi-view-dashboard",
          path: "/invoices?page=1",
          enabled: ACL.checkPermission("list-invoices"),
        },
        {
          title: this.$i18n.t("Payments"),
          icon: "mdi-view-dashboard",
          path: "/payments?page=1",
          enabled:
            ACL.checkPermission("list-payments") ||
            ACL.checkPermission("list-general-payments"),
        },
        {
          title: this.$i18n.t("Bank Receipts"),
          icon: "mdi-view-dashboard",
          path: "/bankReceipts?page=1",
          enabled: ACL.checkPermission("list-payments"),
        },
        {
          title: this.$i18n.t("Accounts Type"),
          icon: "mdi-view-dashboard",
          path: "/allAccounts/types?page=1",
          enabled: ACL.checkPermission("list-account-types"),
        },
        {
          title: this.$i18n.t("Accounts"),
          icon: "mdi-view-dashboard",
          path: "/accounts?page=1",
          enabled: ACL.checkPermission("list-account"),
        },
        {
          title: this.$i18n.t("Discount Packages"),
          icon: "mdi-view-dashboard",
          path: "/discount_packages?page=1",
          enabled: ACL.checkPermission("list-discount-packages"),
        },
      ],
      /*
      digitalLibrary: [
        {
          title: this.$i18n.t("Library"),
          icon: "mdi-view-dashboard",
          path: "/digital_library?page=1",
          enabled: ACL.checkPermission("show-library")
        }
      ],
      */
      classRoom: [
        {
          title: this.$i18n.t("Attendance"),
          icon: "mdi-view-dashboard",
          path: ACL.Role("Teacher")
            ? "/attendance"
            : ACL.Role("Parent")
            ? "/attendanceChild"
            : "/attendance/students",
          enabled:
            !ACL.Role("Student") && ACL.checkPermission("list-attendances"),
        },

        {
          title: this.$i18n.t("Discussion Board"),
          icon: "mdi-view-dashboard",
          path: "/discussionrooms?page=1",
          enabled: ACL.checkPermission("list-discussion-rooms"),
        },
        {
          title: this.$i18n.t("Assignments"),
          icon: "mdi-view-dashboard",
          path: "/homework",
          enabled: ACL.checkPermission("list-home-work"),
        },
        {
          title: this.$i18n.t("Library"),
          icon: "mdi-view-dashboard",
          path: "/digital_library?page=1",
          enabled: ACL.checkPermission("show-library"),
        },
        {
          title: this.$i18n.t("Attendance"),
          icon: "mdi-view-dashboard",
          path: "/studentAttendance",
          enabled: ACL.Role("Student"),
        },
      ],
      Reports: [
        {
          title: this.$i18n.t("Partner Reports"),
          icon: "mdi-view-dashboard",
          Model: "PartnerModel",
          enabled: ACL.checkPermission("print-partner-reports"),
        },
        {
          title: this.$i18n.t("Revenue Reports"),
          icon: "mdi-view-dashboard",
          Model: "revenueReports",
          enabled:
            ACL.checkPermission("revenue-details-reports") ||
            ACL.checkPermission("revenue-summary-reports"),
        },
        {
          title: this.$i18n.t("Cash Reports"),
          icon: "mdi-view-dashboard",
          Model: "cashReports",

          enabled:
            ACL.checkPermission("cash-point-details") ||
            ACL.checkPermission("cash-point-summary"),
        },
        {
          title: this.$i18n.t("Tax Report"),
          icon: "mdi-view-dashboard",
          Model: "taxReport",
          enabled: ACL.checkPermission("tax-report"),
        },
      ],
      Notifications: [
        {
          title: this.$i18n.t("Notifications Logs"),
          icon: "mdi-view-dashboard",
          path: "/notificationsLogs?page=1",
          enabled: ACL.checkPermission("notifications-logs"),
        },
        {
          title: this.$i18n.t("Notification Events"),
          icon: "mdi-view-dashboard",
          path: "/notificationTemplates?page=1",
          enabled: ACL.checkPermission("notification_templates"),
        },
        {
          title: this.$i18n.t("Create Email or SMS"),
          icon: "mdi-view-dashboard",
          path: "/create_mail_sms",
          enabled: ACL.checkPermission("send-custom-email-sms"),
        },
      ],

      Hr: [
        {
          title: this.$i18n.t("HR Configrations"),
          icon: "mdi-view-dashboard",
          path: "/hrConfigrations",
          enabled: ACL.checkPermission("HR-configrations"),
        },
        {
          title: this.$i18n.t("Employees Profiles"),
          icon: "mdi-view-dashboard",
          path: "/employees",
          enabled: ACL.checkPermission("list-employee"),
          // enabled: true,
        },
        {
          title: this.$i18n.t("Employees Salaries"),
          icon: "mdi-view-dashboard",
          path: "/allEmployees/salaries",
          enabled: ACL.checkPermission("list-employee-salary"),
          // enabled: true,
        },
        {
          title: this.$i18n.t("Master Attendance"),
          icon: "mdi-view-dashboard",
          path: "/MasterAttendance",
          enabled: ACL.checkPermission("list-employee-salary"),
          // enabled: true,
        },
        {
          title: this.$i18n.t("Requests"),
          icon: "mdi-view-dashboard",
          path: "/VacationRequests",
          enabled: ACL.checkPermission("vacation-request"),
          // enabled: true,
        },
        {
          title: this.$i18n.t("My requests"),
          icon: "mdi-view-dashboard",
          path: "/LeaveRequest",
          enabled: localStorage.is_employee == "true",
          //enabled: true,
        },
      ],
      EducationalHistory: [
        {
          title: this.$i18n.t("Qualifier Marks"),
          icon: "clipboard-text-clock-outline",
          path: "/educationalhistory/teacherview",
          enabled: ACL.Role("Teacher"),
        },
        {
          title: this.$i18n.t("Subjects Marks"),
          icon: "mdi-view-dashboard",
          path: "/educationalhistory/adminView",
          enabled: ACL.Role("Super-Admin") || ACL.Role("Coordinator") || ACL.Role("Admin"),
        },
        {
          title: this.$i18n.t("Educational History"),
          icon: "mdi-view-dashboard",
          path: "/educationalhistory/studentView",
          enabled: ACL.Role("Student"),
        },
        {
          title: this.$i18n.t("Educational History"),
          icon: "mdi-view-dashboard",
          path: "/educationalhistory/parentView",
          enabled: ACL.Role("Parent"),
        },
      ],

      Nedaa: [
        {
          title: this.$i18n.t("Nedaa"),
          icon: "mdi-view-dashboard",
          path: "/nedaa/child/call",
          enabled: ACL.Role("Parent") || ACL.Role("Driver"),
        },
        {
          title: this.$i18n.t("Leave Time"),
          icon: "mdi-view-dashboard",
          path: "/leavetime",
          enabled: ACL.Role("Super-Admin") || ACL.Role("Admin"),
        },
        {
          title: this.$i18n.t("Settings"),
          icon: "mdi-view-dashboard",
          path: "/nedaa/settings",
          enabled: ACL.Role("Super-Admin") || ACL.Role("Admin"),
        },
        {
          title: this.$i18n.t("Call Links"),
          icon: "mdi-view-dashboard",
          path: "/nedaa/callLinks",
          enabled: ACL.Role("Super-Admin") || ACL.Role("Admin"),
        },
        {
          title: this.$i18n.t("Students QR Codes"),
          icon: "mdi-view-dashboard",
          path: "/studentsQrCode",
          enabled: ACL.Role("Super-Admin") || ACL.Role("Admin"),
        },
        {
          title: this.$i18n.t("Drivers"),
          icon: "mdi-view-dashboard",
          path: "/drivers",
          enabled: ACL.Role("Super-Admin") || ACL.Role("Admin"),
        },
        {
          title: this.$i18n.t("Calls' History"),
          icon: "mdi-view-dashboard",
          path: "/callHistory",
          enabled: ACL.Role("Super-Admin") || ACL.Role("Admin"),
        },
      ],
      miniVariant: false,
      expandOnHover: false,
      background: false,
      mini: true,

      masterDataMenu: {
        offsetX: true,
        offsetY: true,
        value: false,
        closeOnClick: true,
        closeOnContentClick: true,
      },
      HRMenu: {
        offsetX: true,
        offsetY: true,
        value: false,
        closeOnClick: true,
        closeOnContentClick: true,
      },
      academicSetupMenu: {
        offsetX: true,
        offsetY: true,
        value: false,
        closeOnClick: true,
        closeOnContentClick: true,
      },

      studentsMenu: {
        offsetX: true,
        offsetY: true,
        value: false,
        closeOnClick: true,
        closeOnContentClick: true,
      },
      teachersMenu: {
        offsetX: true,
        offsetY: true,
        value: false,
        closeOnClick: true,
        closeOnContentClick: true,
      },

      financeMenu: {
        offsetX: true,
        offsetY: true,
        value: false,
        closeOnClick: true,
        closeOnContentClick: true,
      },

      /*
      digitalLibraryMenu: {
        offsetX: true,
        offsetY: true,
        value: false,
        closeOnClick: true,
        closeOnContentClick: true,
      },*/
      ClassRoomMenu: {
        offsetX: true,
        offsetY: true,
        value: false,
        closeOnClick: true,
        closeOnContentClick: true,
      },
      attendanceMenu: {
        offsetX: true,
        offsetY: true,
        value: false,
        closeOnClick: true,
        closeOnContentClick: true,
      },
      itemMenu: {
        offsetX: true,
        offsetY: true,
        value: false,
        closeOnClick: true,
        closeOnContentClick: true,
      },
      usersMenu: {
        offsetX: true,
        offsetY: true,
        value: false,
        closeOnClick: true,
        closeOnContentClick: true,
      },
      educationalMenu: {
        offsetX: true,
        offsetY: true,
        value: false,
        closeOnClick: true,
        closeOnContentClick: true,
      },
      reportsMenu: {
        offsetX: true,
        offsetY: true,
        value: false,
        closeOnClick: true,
        closeOnContentClick: true,
      },
      NotificationsMenu: {
        offsetX: true,
        offsetY: true,
        value: false,
        closeOnClick: true,
        closeOnContentClick: true,
      },
      // AccountsMenu: {
      //   offsetX: true,
      //   offsetY: true,
      //   value: false,
      //   closeOnClick: true,
      //   closeOnContentClick: true,
      // },
      logo: "",
      school_name: "",
      canSeeTeachersMenu:
        ACL.Role("Teacher") || ACL.checkPermission("list-teachers"),
      canSeeAttendenceMenu: ACL.Role("Teacher") || ACL.Role("Super-Admin"),
      canSeeWarehouses: ACL.checkPermission("list-transactions"),
    };
  },
  components: {
    partnerReportComponent,
    revenueReports,
    cashReportsComponent,
    taxReportComponent,
  },
  computed: {
    getSideBarMin() {
      return this.$store.state.sidebarMin;
    },
    drawer: {
      // getter
      get: function () {
        return this.$vuetify.breakpoint.name == "xs" ||
          this.$vuetify.breakpoint.name == "sm"
          ? this.$store.getters.getSidebarDisplay
          : true;
      },
      set: function () {},
    },
    bg() {
      return this.background
        ? "https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
        : undefined;
    },
    largeScreen() {
      return this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
        ? false
        : true;
    },
  },
  methods: {
    onChildClick(value) {
      this.PartnerModalDialog = value;
      this.revenueModalDialog = value;
      this.cashModalDialog = value;
      this.taxhModalDialog = value;
    },
    OpenReportsModals(modal_name) {
      if (modal_name == "PartnerModel") {
        this.PartnerModalDialog = true;
      }
      if (modal_name == "revenueReports") {
        this.revenueModalDialog = true;
        // console.log(this.revenueModalDialog);
      }
      if (modal_name == "cashReports") {
        this.cashModalDialog = true;
      }
      if (modal_name == "taxReport") {
        this.taxhModalDialog = true;
      }
    },
    masterDatashowMenu() {
      if (this.miniVariant == true) {
        this.masterDataMenu.value = true;
      }
    },
    academicSetupShowMenu() {
      if (this.miniVariant == true) {
        this.academicSetupMenu.value = true;
      }
    },
    HRShowMenu() {
      if (this.miniVariant == true) {
        this.HRMenu.value = true;
      }
    },
    studentshowMenu() {
      if (this.miniVariant == true) {
        this.studentsMenu.value = true;
      }
    },
    teachershowMenu() {
      if (this.miniVariant == true) {
        this.teachersMenu.value = true;
      }
    },
    financeShowMenu() {
      if (this.miniVariant == true) {
        this.financeMenu.value = true;
      }
    },
    /*
    digitalLibraryShowMenu() {
      if (this.miniVariant == true) {
        this.digitalLibraryMenu.value = true;
      }
    },*/
    ClassRoomShowMenu() {
      if (this.miniVariant == true) {
        this.ClassRoomMenu.value = true;
      }
    },

    itemMenuShowMenu() {
      if (this.miniVariant == true) {
        this.itemMenu.value = true;
      }
    },
    usersShowMenu() {
      if (this.miniVariant == true) {
        this.usersMenu.value = true;
      }
    },
    EducationalHistoryShowMenu() {
      if (this.miniVariant == true) {
        this.educationalMenu.value = true;
      }
    },
    notificationsShowMenu() {
      if (this.miniVariant == true) {
        this.NotificationsMenu.value = true;
      }
    },
    // accountsShowMenu() {
    //   if (this.miniVariant == true) {
    //     this.accountsShowMenu.value = true;
    //   }
    // },
    reportsShowMenu() {
      if (this.miniVariant == true) {
        this.reportsMenu.value = true;
      }
    },
    getForm() {
      this.$router.push("/MasterData");
    },
    setSideBarMin() {
      this.miniVariant = !this.miniVariant;
      this.$store.commit("setSideBarMin");
    },
    closeSidebar() {
      this.$store.commit("setSidebarDisplay");
      // this.drawer = false;
    },
    canSee(arr) {
      return (
        arr.find((element) => {
          return element.enabled == true;
        }) != undefined
      );
    },
    menuHasActive() {
      let path = window.location.pathname;
      let arraySchoolSetup = [
        "/MasterData",
        "/academics",
        "/subject_type",
        "/subjects",
        "/grades",
        "/workingdays",
        "/gradingsystem",
      ];
      let arrayAcademicSetup = [
        "/curriculum_setup/active",
        "/qualifier",
        "/classmanagement/all",
      ];
      let arrayStudents = ["/applicants", "/admissionExam", "/students"];

      let arrayTeachers = ["/teachers"];

      let arrayFianacial = [
        "/financialstatus",
        "/feeplans",
        "/taxes",
        "/cashpoints",
        "/partners",
        "/invoices",
        "/payments",
        "/bankReceipts",
        "/allAccounts/types",
        "/accounts",
        "/discount_packages",
      ];

      let arrayClassRoom = [
        "/attendance/students",
        "/discussionrooms",
        "/homework",
        "/digital_library",
        "/studentAttendance",
        "/attendanceChild",
      ];

      let arrayItemsSetup = ["/itemsCategories", "/items"];

      let arrayWarehouses = [
        "/WarehouseTransaction",
        "/BooksList",
        "/WarehouseItems",
      ];

      let arrayNotifications = ["/notificationsLogs", "/notificationTemplates"];

      let arrayHrmodule = [
        "/hrConfigrations",
        "/employees",
        "/allEmployees/salaries",
        "/MasterAttendance",
        "/VacationRequests",
      ];

      let arrayUsers = ["/user_management", "/all_users"];

      if (arraySchoolSetup.includes(path)) {
        this.$refs.schoolSetup.click();
      } else if (arrayAcademicSetup.includes(path)) {
        this.$refs.academicSetup.click();
      } else if (arrayStudents.includes(path)) {
        this.$refs.students.click();
      } else if (arrayTeachers.includes(path)) {
        this.$refs.teachers.click();
      } else if (arrayFianacial.includes(path)) {
        this.$refs.fianacial.click();
      } else if (arrayClassRoom.includes(path)) {
        this.$refs.classRoom.click();
      } else if (arrayItemsSetup.includes(path)) {
        this.$refs.itemsSetup.click();
      } else if (arrayWarehouses.includes(path)) {
        this.$refs.Warehouses.click();
      } else if (arrayNotifications.includes(path)) {
        this.$refs.Notifications.click();
      } else if (arrayHrmodule.includes(path)) {
        this.$refs.hrmodule.click();
      } else if (arrayUsers.includes(path)) {
        this.$refs.Users.click();
      }
    },
  },
  mounted() {
    //console.log(localStorage.school_name != "null");
    this.school_name =
      localStorage.school_name && localStorage.school_name != "null"
        ? localStorage.school_name
        : "SMS";
    this.logo = localStorage.logo ? localStorage.logo : "null";
    this.menuHasActive();
    console.log("this.currentAppLocale", this.currentAppLocale);
  },
};
</script>

<style scoped lang="scss">
@import "../styles/_variables.scss";
@import "../styles/_print.scss";

.main-sidebar {
  background-color: $main-color !important;
  z-index: 11;
}
.v-list-item {
  min-height: 35px;
}
.single {
  padding-left: 16px !important;
}
</style>
