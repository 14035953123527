<template>
  <v-dialog v-model="taxhModalDialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("Tax Reports") }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid"  class="mt-5">
            <v-row>
              <v-col cols="12">
                <div class="form-group">
                  <label>{{ $t("Nationality") }}</label>
                  <v-autocomplete
                    v-model="taxData.nationalityReq"
                    solo
                    :items="['All', 'Saudi Arabia', 'non Saudi Arabia']"
                    required
                    @change="hideLabel = true"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="form-group">
                  <label>{{ $t("Date") }}</label>
                </div>
              </v-col>

              <v-col cols="6" class="parent">
                <div class="form-group">
                  <label>{{ $t("From") }}</label>
                  <v-text-field
                    v-model="taxData.from"
                    solo
                    append-icon="calendar_today"
                    id="taxDatefrom"
                    autocomplete="off"
                    :rules="[startDateRule(taxData.from, taxData.to)]"
                    @keydown.prevent
                    @paste.prevent
                    @drop.prevent
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="form-group">
                  <label>{{ $t("to") }}</label>
                  <v-text-field
                    v-model="taxData.to"
                    solo
                    append-icon="calendar_today"
                    id="taxDateto"
                    autocomplete="off"
                    :rules="[startDateRule(taxData.from, taxData.to)]"
                    @keydown.prevent
                    @paste.prevent
                    @drop.prevent
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel cancelBtn" @click="close">{{
          $t("Close")
        }}</v-btn>
        <v-btn
          class="modal-btn-save saveBtn"
          :loading="loading"
          @click="PrintTaxReport"
          >{{ $t("Print") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";

import moment from "moment";
export default {
  props: ["taxhModalDialog"],
  name: "taxReport",
  data() {
    return {
      valid: false,
      taxData: {
        nationalityReq: "",
        from: "",
        to: ""
      },
      loading: false
    };
  },
  mixins: [validationMixin],
  watch: {
    taxhModalDialog: {
      handler() {
        if (this.taxhModalDialog == true) {
          setTimeout(() => {
            this.activateDatePicker();
          }, 500);
        }
      },
      deep: true
    }
  },
  methods: {
    close() {
      this.taxhModalDialog = false;
      this.taxData = {
        nationalityReq: "",
        from: "",
        to: ""
      };
      this.$emit("childToParent", false);
    },
    activateDatePicker() {
      let _this = this;
      $(document).ready(function() {
        $("#taxDatefrom").calendarsPicker({
          dateFormat: "dd/mm/yyyy",

          onSelect: function(date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.taxData.from = dateH;
            } else {
              _this.taxData.from = "";
            }
          }
        });
        $("#taxDateto").calendarsPicker({
          dateFormat: "dd/mm/yyyy",

          onSelect: function(date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.taxData.to = dateH;
            } else {
              _this.taxData.to = "";
            }
          }
        });
      });
    },
    startDateRule(start, end) {
      var startDate = moment(start, "DD/MM/YYYY");
      var endDate = moment(end, "DD/MM/YYYY");
      return startDate > endDate
        ? "Start date should be less than end date"
        : true;
    },
    PrintTaxReport() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        this.loading = true;
        axios
          .post(this.getApiUrl + "/reports/taxReport", this.taxData, {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          })
          .then(response => {
            var link = response.data.data;
            window.open(link, "_blank");
          });
        this.loading = false;
      }
    }
  },
  mounted() {
    this.activateDatePicker();
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.innerDiv label {
  margin-top: 0 !important;
}
.imageDiv {
  img {
    max-width: 40px;
  }
}
#partnerDatafrom {
  position: absolute;
}
.parent {
  position: relative;
  z-index: 99;
}
@media (max-width: 600px) {
  .imageDiv {
    img {
      max-width: 30px;
    }
  }
  .content {
    font-size: 10px;
  }
}
.content {
  font-weight: normal !important;
}
.radio-section {
  margin-top: 0;
}

label {
  font-size: 14px !important;
  margin-top: 0 !important;
}
</style>
