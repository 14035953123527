<template>
  <v-dialog v-model="cashModalDialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("Print CashPoint") }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row class="space">
            <v-col cols="12" sm="12" md="12">
              <v-tabs
                v-model="tab"
                background-color="primary"
                dark
                id="printTabsHeaders"
              >
                <v-tab v-if="summaryPermission">
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="imageDiv text-center">
                        <img src="../../assets/icons/summary.png" alt="" />
                      </div>
                    </v-col>
                    <v-col cols="12" md="12">
                      <div class="content main-color">{{ $t("Summary") }}</div>
                    </v-col>
                  </v-row>
                </v-tab>
                <v-tab class="text-center" v-if="detailPermission">
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="imageDiv text-center">
                        <img src="../../assets/icons/details.png" alt="" />
                      </div>
                    </v-col>
                    <v-col cols="12" md="12">
                      <div class="content main-color">
                        {{ $t("Details") }}
                      </div>
                    </v-col>
                  </v-row>
                </v-tab>
              </v-tabs>

              <v-card id="printTabsCard">
                <v-tabs-items v-model="tab" id="printTabsItems">
                  <v-tab-item v-if="summaryPermission">
                    <v-form ref="form" v-model="valid">
                      <v-row>
                        <v-col cols="12">
                          <div class="form-group">
                            <label>{{ $t("Cash Point(s)") }} </label>
                            <v-autocomplete
                              v-model="cashData.cash_points_ids"
                              :items="cash_points"
                              solo
                              @change="hideLabel = true"
                              item-text="name"
                              item-value="id"
                              multiple
                              chips
                            >
                            </v-autocomplete>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <div class="form-group">
                            <label>{{ $t("Types") }} </label>
                            <v-autocomplete
                              v-model="cashData.types"
                              solo
                              @change="hideLabel = true"
                              :items="types"
                              multiple
                              chips
                            >
                            </v-autocomplete>
                          </div>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-tab-item>
                  <v-tab-item v-if="detailPermission">
                    <v-form ref="form" v-model="valid">
                      <v-row>
                        <v-col cols="12">
                          <div class="form-group">
                            <label>{{ $t("Cash Point(s)") }} </label>
                            <v-autocomplete
                              v-model="cashData.cash_points_ids"
                              :items="cash_points"
                              solo
                              @change="hideLabel = true"
                              item-text="name"
                              item-value="id"
                              multiple
                              chips
                            >
                            </v-autocomplete>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <div class="form-group">
                            <label>{{ $t("Types") }} </label>
                            <v-autocomplete
                              v-model="cashData.types"
                              solo
                              @change="hideLabel = true"
                              :items="types"
                              multiple
                              chips
                            >
                            </v-autocomplete>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <div class="form-group">
                            <label>{{ $t("Cashier") }} </label>
                            <v-autocomplete
                              v-model="cashData.cashiers"
                              solo
                              @change="hideLabel = true"
                              :items="cashiers"
                              item-text="name"
                              item-value="id"
                              multiple
                              chips
                            >
                            </v-autocomplete>
                          </div>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel cancelBtn" @click="close">{{
          $t("Close")
        }}</v-btn>
        <v-btn
          class="modal-btn-save saveBtn"
          :loading="loading"
          @click="PrintCashReport"
          >{{ $t("Print") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
import ACL from "../../acl";
export default {
  name: "partnerReportComponent",
  props: ["cashModalDialog"],
  data() {
    return {
      tab: null,
      cashData: {
        cash_points_ids: [],
        types: [],
        cashiers: []
      },
      cash_points: [],
      types: [],
      cashiers: [],
      valid: false,
      partners: [],
      detailPermission: ACL.checkPermission("cash-point-details"),
      summaryPermission: ACL.checkPermission("cash-point-summary"),
      loading: false
    };
  },
  mixins: [validationMixin],
  watch: {
    cashModalDialog: {
      handler() {
        if (this.cashModalDialog == true) {
          this.getCashPoints();
        }
      },
      deep: true
    },
    "cashData.cash_points_ids": {
      handler() {
        this.getTypesAndAssignee();
      },
      deep: true
    }
  },
  methods: {
    close() {
      this.cashData = {
        cash_points_ids: [],
        types: [],
        cashiers: []
      };
      this.cashModalDialog = false;
      this.$emit("childToParent", false);
    },
    printSummary() {
      this.loading = true;
      axios
        .post(this.getApiUrl + "/reports/cashpoints/summary", this.cashData, {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          // console.log(response.data.data);
          this.loading = false;
          var link = response.data.data;
          window.open(link, "_blank");
        });
    },
    printDetails() {
      this.loading = true;
      axios
        .post(this.getApiUrl + "/reports/cashpoints/details", this.cashData, {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          //   console.log(response.data.data);
          this.loading = false;
          var link = response.data.data;
          window.open(link, "_blank");
        });
    },
    PrintCashReport() {
      if (this.valid == false) {
        this.$refs.form.validate();
      } else {
        if (this.tab == 0 && this.summaryPermission) {
          this.printSummary();
        } else if (this.tab == 0 && !this.summaryPermission) {
          this.printDetails();
        } else {
          this.printDetails();
        }
      }
    },
    getTypesAndAssignee() {
      axios
        .post(
          this.getApiUrl +
            "/reports/cashpoints/get_all_cashpoints_types_assignee",
          { cash_points_ids: this.cashData.cash_points_ids },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          this.types = response.data.data.types;
          this.cashiers = response.data.data.cashiers;
        });
    },
    getCashPoints() {
      axios
        .get(this.getApiUrl + "/reports/cashpoints/all", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          this.cash_points = response.data.data;
        });
    }
  },
  mounted() {
    this.getCashPoints();
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.innerDiv label {
  margin-top: 0 !important;
}
.imageDiv {
  img {
    max-width: 40px;
  }
}
#partnerDatafrom {
  position: absolute;
}
.parent {
  position: relative;
  z-index: 99;
}
@media (max-width: 600px) {
  .imageDiv {
    img {
      max-width: 30px;
    }
  }
  .content {
    font-size: 10px;
  }
}
.content {
  font-weight: normal !important;
}
.radio-section {
  margin-top: 0;
}

label {
  font-size: 14px !important;
  margin-top: 0 !important;
}
</style>
